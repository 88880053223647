import React from 'react'

import { Layout } from 'components'

import { ContactUsSection } from 'sections'

const ContactPage = () => (
  <Layout title="Contact">
    <ContactUsSection />
  </Layout>
)

export default ContactPage
